import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '@components/Layout';
import Content, { HTMLContent } from '@components/content/Content';

export const AboutPageTemplate = ({
  image,
  title,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section>
      <h2 className="title">{title}</h2>
      <Img alt="Decorative Image" className="hero" fluid={image.childImageSharp.fluid} />
      <Img alt="Decorative Image" fixed={image.childImageSharp.fx1} />
      <Img alt="Decorative Image" fixed={image.childImageSharp.fx2} />
      <Img alt="Decorative Image" fixed={image.childImageSharp.fx3} />
      <Img alt="Decorative Image" fixed={image.childImageSharp.fx4} />
      <PageContent className="content" content={content} />
    </section>
  );
};

AboutPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        image={post.frontmatter.image}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fixed: fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
            fx1: fixed(
              width: 300
              duotone: { highlight: "#f00e2e", shadow: "#333333", opacity: 90 }
            ) {
              ...GatsbyImageSharpFixed
            }
            fx2: fixed(width: 300, quality: 100, grayscale: true) {
              ...GatsbyImageSharpFixed
            }
            fx3: fixed(
              width: 300
              cropFocus: NORTHEAST
              duotone: { highlight: "#ccddff", shadow: "#333333", opacity: 90 }
            ) {
              ...GatsbyImageSharpFixed
            }
            fx4: fixed(
              rotate: 180
              width: 300
            ) {
              ...GatsbyImageSharpFixed
            }
            fluid: fluid(
              maxWidth: 2048
              quality: 100
              duotone: { highlight: "#f00e2e", shadow: "#333333", opacity: 90 }
              traceSVG: {
                color: "#f00e2e"
                turnPolicy: TURNPOLICY_MINORITY
                blackOnWhite: false
              }
            ) {
              ...GatsbyImageSharpFluid
              tracedSVG
            }
          }
        }
      }
    }
  }
`;
